<!-- 门票订单 -->

<template>
  <div class="order-tickets main-cnt">
    <div class="title">订单列表</div>
    <div class="content">
      <common-table ref="tableRef" :needExport="true" :needPrint="true" tableHeight="calc(100vh - 320px)"
        :ischeck="false" :filters="filters" :groupFilters="groupFilters" :ispaging="true"
        :apiName="OrderApi.hotelTicketList" :columns="tableColumns" @detail="openDetailDialog"
        @hotelChange="hotelChange" @export="handleExport" @print="handlePrint" @resettingBtn="resettingBtn">
        <template #pay_status="{ row }">
          <span :style="'color:' + payStatusColors[row.pay_status]">{{
            payStatusOptions[row.pay_status]
          }}</span>
        </template>
        <template #work_status="{ row }">
          <span :style="'color:' + workStatusColors[row.work_status]">{{
            workStatusOptions[row.work_status]
          }}</span>
        </template>
        <template #startrRefund="{ row }">
          <template v-if="
              row.enable_refund =='2' &&
              authData.indexOf('o_789OalFvc0XbJEpL4DqAMnd5RmoC') != -1
            ">
            <el-button class="black-font-btn" @click="openStartrRefundDialog(row)">发起退款</el-button>
          </template>
        </template>
        <template #printOtder="{ row }">
          <template v-if="row.pay_status == 2">
            <el-button class="theme-font-btn" @click="lodopSn(row)">打印</el-button>
          </template>
        </template>
        <!-- <template #sureRefund="{ row }">
          <template v-if="
              row.pay_status == 4 &&
              authData.indexOf('o_4WIZpegzLqUraKfYx5RD0S6buoGA') != -1
            ">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)">确认退款</el-button>
          </template>
        </template> -->
      </common-table>
    </div>

    <!-- 订单详情弹窗 -->
    <TicketDetailDialog ref="detailDialogRef" @submit="resettingBtn"></TicketDetailDialog>
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>
    <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>

    <!-- 打印 -->
    <PrintInfo ref="printInfoRef"></PrintInfo>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { OrderApi, BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import TicketDetailDialog from "../components/TicketDetailDialog.vue";
  import RefundDialog from "../components/RefundDialog.vue";
  import PrintOrder from "../components/PrintOrder.vue";
  import PrintInfo from "@/components/print-info/print.vue";
  import { useStore } from "vuex";
  import {
    exportExcel,
    print,
    getPayStatusColors,
    getWorkStatusColors,
    getPayStatusOptions,
    getTicketStatusOptions,
  } from "@/utils/common.js";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  const filters = ref([
    {
      filterType: "searchKey",
      name: "keyword",
      value: "",
      placeholder: "搜索订单号、门票名称、手机号",
    },
    {
      filterType: "select",
      name: "st_id",
      value: "",
      placeholder: "请选择园区",
      options: [],
      val: "s_id",
      lab: "s_name",
      action: "hotelChange",
    },
  ]);
  /**
  *
  * 获取园区选项
  *
  * */
  const getParkDatas = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取优惠券类型失败！";
        ElMessage.error(msg);
      }
    });
  }
  onMounted(() => {
    getParkDatas();
    tableRef.value.tableLoad();
    getPayStatusLabelArr();
    getWorkStatusLabelArr();
    getSourceOptions();
    getOrderTicketPayWayList();
  });
  /**
   * 
   * 组合查询重置按钮
   * 
   * */
  const resettingBtn = () => {
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 获取门票订单支付方式列表
   * 
   * */
  const getOrderTicketPayWayList = () => {
    OrderApi.getOrderTicketPayWayList().then((res) => {
      if (res.Code === 200) {
        groupFilters.value[3].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }






  const payStatusOptions = ref(getPayStatusOptions());
  const payStatusColors = ref(getPayStatusColors());
  const workStatusOptions = ref(getTicketStatusOptions());
  const workStatusColors = ref(getWorkStatusColors());
  const payStatusLabelArr = ref([]);
  function getPayStatusLabelArr() {
    for (var key in payStatusOptions.value) {
      payStatusOptions.value[key] &&
        payStatusLabelArr.value.push({
          label: payStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[1].options = payStatusLabelArr.value;
  }
  const workStatusLabelArr = ref([]);
  function getWorkStatusLabelArr() {
    for (var key in workStatusOptions.value) {
      workStatusOptions.value[key] &&
        workStatusLabelArr.value.push({
          label: workStatusOptions.value[key],
          value: +key,
        });
    }
    groupFilters.value[2].options = workStatusLabelArr.value;
  }
  function getSourceOptions() {
    BasicApi.orderSourceOptions({}).then((res) => {
      if (res.Code === 200) {
        groupFilters.value[0].options = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取订单来源数据失败！";
        ElMessage.error(msg);
      }
    });
  }


  const groupFilters = ref([
    {
      filterType: "select",
      name: "source",
      value: "",
      label: "购买渠道",
      options: [],
      val: "id",
      lab: "name",
    },
    {
      filterType: "select",
      name: "pay_status",
      value: 2,
      label: "支付状态",
      options: [],
      val: "value",
      lab: "label",
      otherOptionsName: "work_status",
      showOtherOptionsValue: 2,
    },
    {
      filterType: "multipleSelect",
      name: "work_status",
      value: [],
      label: "业务状态",
      options: [],
      val: "value",
      lab: "label",
    },
    {
      filterType: "multipleSelect",
      name: "payway",
      value: [],
      label: "付款方式",
      options: [],
      val: "orp_id",
      lab: "orp_type_name",
    },
    {
      filterType: "partDaterange",
      name1: "stime",
      value1: "",
      name2: "etime",
      value2: "",
      label: "支付时间",
    },
  ]);

  const tableRef = ref(null);
  const hotelChange = (data) => {
    // filters.value[2].options = [];
    // filters.value[2].value = "";
    tableRef.value.tableLoad();
    if (data && data.value) {
      BasicApi.rolebindList({ s_id: data.value }).then((res) => {
        if (res.Code === 200) {
          if (res.Data && res.Data.length > 0) {
            res.Data.forEach((item) => {
              if (item.type == 1) {
                item.id = item.s_id;
                item.keyValue = item.type + "-" + item.s_id;

                item.name = "园区-" + item.s_name;
              } else {
                item.id = item.sp_id;
                item.keyValue = item.type + "-" + item.sp_id;
                item.name = "景点-" + item.sp_name;
              }
            });
          }
          // filters.value[2].options = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取户型数据失败！";
          ElMessage.error(msg);
        }
      });
    }
  };
  const tableColumns = ref([
    {
      prop: "sn",
      label: "订单号",
      minWidth: 240,
      color: "--text-third-color",
    },
    {
      prop: "ticket_name",
      label: "门票名称",
      minWidth: 130,
    },
    {
      prop: "ticket_type_name",
      label: "门票类型",
      color: "--text-third-color",
      minWidth: 100,
    },
    {
      prop: "buy_num",
      label: "购买数量",
      color: "--theme-color",
      minWidth: 80,
    },
    {
      prop: "refund_num",
      label: "退款数量",
      color: "--text-third-color",
      minWidth: 80,
    },
    {
      prop: "pay_money",
      label: "支付金额",
      minWidth: 80,
    },
    {
      prop: "refund_money",
      label: "退款金额",
      minWidth: 80,
      color: "--text-third-color",
    },
    {
      prop: "yh_money",
      label: "优惠金额",
      minWidth: 80,
    },
    {
      prop: "coupon_money",
      label: "代金券",
      minWidth: 70,
      color: "--text-third-color",
    },
    {
      prop: "m_mobile",
      label: "联系电话",
      minWidth: 120,
      color: "--text-third-color",
    },
    {
      prop: "payway_name",
      label: "支付方式",
      minWidth: 80,
      color: "--text-third-color",
    },
    {
      prop: "pay_status",
      label: "支付状态",
      color: "--text-third-color",
      type: "customRender",
      minWidth: 80,
    },
    {
      prop: "work_status",
      label: "业务状态",
      color: "--text-third-color",
      type: "customRender",
      minWidth: 80,
    },
    {
      prop: "pay_time",
      label: "支付时间",
      color: "--text-third-color",
      minWidth: 150,
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 280,
      bottons: [
        {
          name: "详情",
          action: "detail",
          token: "o_DeQbXuYNhBU6tpmnPx8oRcdgjs2W",
        },
        {
          name: "发起退款",
          action: "startrRefund",
          type: "customRender",
        },
        {
          name: "打印",
          action: "printOtder",
          type: "customRender",
        },
        // {
        //   name: "确认退款",
        //   action: "sureRefund",
        //   type: "customRender",
        // },
      ],
    },
  ]);

  const initRefundRef = ref(null);
  const currentRefundSn = ref("");
  const openStartrRefundDialog = (row) => {
    currentRefundSn.value = row.sn;
    const lastMoney = +row.pay_money - +row.refund_money;
    initRefundRef.value.openDialog("发起退款", lastMoney, row.enable_refund_money);
  };
  function handleInitRefund(data) {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    OrderApi.initRefund(parmas).then((res) => {
      initRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        initRefundRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }
  // 确认退款
  const sureRefundRef = ref(null);
  function openSureRefundDialog(row) {
    currentRefundSn.value = row.sn;
    sureRefundRef.value.openDialog(
      "确认退款",
      row.pay_money,
      row.confirm_refund_money
    );
  }
  function handleSureRefund(data) {
    const parmas = {
      sn: currentRefundSn.value,
      ...data,
    };
    OrderApi.sureRefund(parmas).then((res) => {
      sureRefundRef.value.closeDialogLoading();
      if (res.Code === 200) {
        sureRefundRef.value.closeDialog();
        tableRef.value.tableLoad();
        ElMessage.success("操作成功");
      } else {
        let msg = res.Message ? res.Message : "操作失败！";
        ElMessage.error(msg);
      }
    });
  }

  const detailDialogRef = ref(null);

  function openDetailDialog(row) {
    detailDialogRef.value.openDialog(row.sn, {
      payStatus: {
        options: payStatusOptions.value,
        colors: payStatusColors.value,
      },
      workStatus: {
        options: workStatusOptions.value,
        colors: workStatusColors.value,
      },
    });
  }
  const propertiesConfig = ref([
    { field: "sn", displayName: "订单号" },
    { field: "ticket_name", displayName: "门票名称" },
    { field: "ticket_type_name", displayName: "门票类型" },
    { field: "validity", displayName: "门票有效期" },
    { field: "buy_num", displayName: "购买数量" },
    { field: "refund_num", displayName: "退款数量" },
    { field: "pay_money", displayName: "支付金额" },
    { field: "refund_money", displayName: "退款金额" },
    { field: "yh_money", displayName: "优惠金额" },
    { field: "coupon_money", displayName: "代金券" },
    { field: "agent_name", displayName: "分销商" },
    { field: "payway_name", displayName: "支付方式" },
    { field: "pay_status_text", displayName: "支付状态" },
    { field: "work_status_text", displayName: "业务状态" },
    { field: "pay_time", displayName: "支付时间" },
    { field: "source_text", displayName: "购买渠道" },
  ]);
  function processData(data) {
    data.length &&
      data.forEach((el) => {
        el.work_status_text = workStatusOptions.value[el.work_status];
        el.validity =
          el.sdate === el.edate ? el.sdate + "当天" : el.sdate + "至" + el.edate;
      });
    return data;
  }
  function handlePrint(data) {
    print("门票订单", processData(data), propertiesConfig.value);
  }
  function handleExport(data) {
    try {
      exportExcel("门票订单", processData(data), propertiesConfig.value);
      ElMessage.success({
        message: "导出成功！",
      });
    } catch (e) {
      ElMessage.error({
        message: "导出失败！",
      });
    }
  }

  //打印小票
  const printInfoRef = ref(null);
  function lodopSn(row) {
    OrderApi.printOrder({
      sn: row.sn
    }).then(res => {
      if (res.Code == 200) {
        printInfoRef.value.setDataAndPrint(res.Data)
      } else {
        ElMessage.error(res.Message);
      }
    })
  }
</script>
<style lang="scss">
  .order-tickets {
    font-family: "Source Han Sans CN";


    .el-input-group__append {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .content {
      padding: 15px 20px 20px;
    }

    .font-gray {
      color: var(--text-gray-color);
    }

    .font-999 {
      color: var(--text-third-color);
    }

    .refund-money {
      width: 100%;
      padding: 20px 0;
      background-color: var(--theme-bg-color);
      text-align: center;
      border-radius: 10px;
      margin-bottom: 20px;
      color: var(--text-gray-color);

      >div {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
</style>